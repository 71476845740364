<template>
  <TopNavBar />

  <!-- breadcrumb area start -->
  <div
    class="breadcrumb-area"
    style="background-image: url(assets/img/page-title-bg.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-inner">
            <h1 class="page-title">IT Services</h1>
            <ul class="page-list">
              <li>
                <router-link class="nav-link" :to="{ name: 'home' }"
                  >Home</router-link
                >
              </li>
              <li>IT Services</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- breadcrumb area End -->

  <!-- about area start -->
  <div class="about-provide-area pd-top-120 bg-none">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-6">
          <div class="img-with-video">
            <div class="img-wrap">
              <img src="assets/img/work-processing/3.png" alt="video" />
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-6 desktop-center-item">
          <div>
            <div class="section-title style-two">
              <h2 class="title">IT Services</h2>
              <!-- <p>
                If you like us to help integrate our data and tools into your
                systems, we will be happy to discuss the possibilities based on
                your specific setup.
              </p> -->
              <p>
                Our tools are based on standard data formats and protocols.
                Should your particular setup require custom integration into
                legacy systems, we pride ourselves on finding a solution that
                fits your needs.
              </p>
              <p>
                We understand that not all organisations are alike and do not
                always have spare IT capacity. Based on our team’s experience
                with multiple platforms in a variety of settings, we can assist
                you with your specific requirements.
              </p>
              <p>
                We will be happy to discuss the possibilities based on your
                specific situation. Feel free to contact us.
              </p>
            </div>
            <div class="text-center">
              <a
                class="btn btn-radius btn-blue s-animate-3 mt-3"
                href="mailto:info@fareplay.aero"
                ><i class="fa fa-paper-plane"></i> Contact us</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- about area End -->

  <LetsTalk />
  <Footer />
</template>

<script>
import TopNavBar from "@/components/TopNavBar.vue";
import LetsTalk from "@/components/LetsTalk.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "ITServices",
  components: {
    TopNavBar,
    LetsTalk,
    Footer,
  },
};
</script>